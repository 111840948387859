<template>
  <div class="content">
    <div class="home">
      <img src="@/assets/images/home-background.png" alt="" />
      <div class="title">
        <div class="title_text1">CITADEX FAMILY OFFICE</div>
        <div class="title_text2">堡得家族办公室</div>
      </div>
      <div class="home_btn">
        <div class="home_btn_title">家族传承发展及财富管理3.0时代</div>
        <div class="home_btn_text">
          安全-传承-保值-增值<br />Security - Inheritance - Value Preservation -
          Value Addition
        </div>
      </div>
    </div>
    <div class="nav">
      <img src="@/assets/images/nav.jpg" alt="" />
      <div class="nav_content">
        <img src="@/assets/logo.png" alt="" />
        <div class="nav_content_title">EXECUTIVE<br />SUMMARY 主题概要</div>
        <div class="nav_content_list">
          <div v-for="(item, index) in navList" :key="index" class="nav_content_list_item">
            <div class="item_number">{{ item.id }}</div>
            <div class="item_title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="market_overview">
      <img src="@/assets/logo.png" alt="" />
      <div class="market_overview_header">
        <div class="number">Ⅰ</div>
        <div class="title">
          Market overview<br /><span>家族办公室概况</span>
        </div>
      </div>
      <div class="market_overview_section">
        <div class="market_overview_section_left">
          <div class="market_overview_section_left_title">概念定义</div>
          <div class="market_overview_section_left_text">
            根据美国家族办公室协会(Family Office Association)的定义，家族
            办公室是专为超高净值家庭提供全方位财富管理和家族服务，使资产
            ⻓期发展，并能顺利进行跨代传承和保值增值的机构。主要功能是治
            理和管理家族4大资本:金融资本、家族资本、人力资本、社会资本
          </div>
          <div class="market_overview_section_left_title">主要注册地差异</div>
          <div>
            <div class="market_overview_section_left_text">
              中国大陆:多为信托公司、商业银行，保险和律师背景，成立大多不
              足五年，尚处起步阶段
            </div>
            <div class="market_overview_section_left_text">
              <span>中国香港</span>
              ：全球最大的离岸人⺠币交易中心，全球三大国际中
              心，稳健的金融制度、多元化高效的资本市场、简单有力的税制、便
              利的营商环境、健全的法律体制，信息和资金自由流通、专业人才汇
              聚，具备国际化的全方位支持
            </div>
            <div class="market_overview_section_left_text">
              新加坡:具备大多香港的制度优势，但资本市场规模远小于香港，劳
              动力和其他运营成本较高，合规和税务的要求也更高，且需要适应不
              同的商业和文化⻛俗
            </div>
          </div>
        </div>
        <div class="market_overview_section_right">
          <img src="@/assets/images/market-overview-image.png" alt="" />
        </div>
      </div>
    </div>
    <div class="market_overview">
      <img src="@/assets/logo.png" alt="" />
      <div class="market_overview_header">
        <div class="number">Ⅰ</div>
        <div class="title">
          Market overview<br /><span>家族办公室市场规模及行业前景</span>
        </div>
      </div>
      <div class="market_overview_section" style="align-items: flex-start">
        <div class="market_overview_section_left" style="margin-top: 4%; width: 33%">
          <div class="market_overview_section_left_title">
            国内家族财富规模趋势
          </div>
          <div class="market_overview_section_left_text">
            根据胡润百富报告显示，2023年中国拥有千万人民币家庭净资产的“高
            净值家庭”数量达到 211万户，拥有亿元人民币家庭净资产的“超高净
            值家庭”数量达到13.8万户。拥有高净值家庭最多的五个城市是北京、
            上海、香港 、深圳 、广州。
          </div>
          <div class="market_overview_section_left_title">
            全球家族财务规模趋势
          </div>
          <div>
            <div class="market_overview_section_left_text">
              在亚太区，超高资产净值人士占比全球约27%，超过16.9万名;香港私人银行和私人财富管理业务中，来自家族办公室和私人信托客户的资产规模高达1.78万亿港元。过去3年，亚洲区(日本除外)管理资产规模(AUM)增长近50%，超高净值家族在全方位联合型家族办公室服务方面需求强劲。
            </div>
            <div class="market_overview_section_left_text">
              在北美区，有30%的下一代已经接管他们家族的运营，27%的人预计将在未来十年内接管。家族权力交接反映了代际财富转移的大趋势。研究公司Cerulli
              Associates预测，2021年至2045年间代际传承的总财富将达到84.4万亿美元。只有33%的家族办公室制定了核心成员继任计划，40%的家族办公室认为他们还没有找到足够资格接班的下一代成员。
            </div>
            <div class="market_overview_section_left_text">
              报告显示，全球家族办公室正处于战略资产配置(SAA)的新时代，高通胀、央行流动性和利率上升迫使它们重新审视自己的投资选择。包含股票、黄金、房地产、固定收益、保险保障等在内的多元化投资组合是对冲通胀的最好方式。他们正在减少固定收益资产的配置，增加对私人股本、房地产和私人债务的投资，用流动性换取回报。
            </div>
          </div>
        </div>
        <div class="market_overview_section_right">
          <img src="@/assets/images/citadex-advantage.png" alt="" />
        </div>
      </div>
    </div>
    <div class="market_overview timeline1">
      <img src="@/assets/logo.png" alt="" />
      <div class="market_overview_header">
        <div class="number">Ⅱ</div>
        <div class="title">
          Industry Pain Points And Timeline<br /><span>家族办公室行业痛点及时间节点</span>
        </div>
      </div>
      <div class="timeline_section">
        <div class="timeline_section_left">
          <div v-for="(item, index) in timelienList" :key="index" class="timeline_section_left_item">
            <div class="number">{{ item.id }}</div>
            <div class="title" v-html="item.title"></div>
          </div>
        </div>
        <div class="timeline_section_right">
          <img src="@/assets/images/timeline.png" alt="" />
        </div>
      </div>
    </div>
    <div class="timeline2">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅱ</div>
          <div class="title">
            Industry Pain Points And Timeline<br /><span>行业痛点及时间节点</span>
          </div>
        </div>
        <div class="timeline2_section">
          <div class="timeline2_section_title">时间节点：为什么是现在</div>
          <div class="timeline2_section_title_tip">最新的政策支持:</div>
          <div class="list_title">香港《施政报告》</div>
          <div class="list">
            <div class="list_left">
              <div class="list_title_tip">
                2025年年底前，推动不少于200个家族办公室在香港设立或扩展业务。
              </div>
              <div class="options">
                <div v-for="(item, index) in policyList" :key="index" class="list_title_item">
                  {{ "[ " + item.id + " ]" + item.title }}
                </div>
              </div>
            </div>
            <div class="list_right">
              此外，香港 于2023年6月1日实施虚拟资产服务提供者发牌制度，<br />( “
              VASP ”)
              即允许个人投资者合法合规投资主流虚拟货币，香港或将成为亚洲甚至全球的虚拟资产交易中心。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="our_advantage">
      <img src="@/assets/logo.png" alt="" />
      <div class="our_advantage_number">Ⅴ</div>
      <div class="our_advantage_tilte">Our advantage</div>
      <div class="our_advantage_tip">
        <div></div>
        财富管理3.0应具备的优势
        <div></div>
      </div>
      <div class="our_advantage_list">
        <div class="our_advantage_text">
          <div class="number">NO.1</div>
          <div class="title">海外先进的管理理念</div>
        </div>
        <div class="our_advantage_text">
          <div class="number">NO.2</div>
          <div class="title">科学拆解的服务流程</div>
        </div>
        <div class="our_advantage_text">
          <div class="number">NO.3</div>
          <div class="title">综合的投资管理能力</div>
        </div>
        <div class="our_advantage_text">
          <div class="number">NO.4</div>
          <div class="title">AI智能的风控体系</div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>先进的管理理念</span>
          </div>
        </div>
        <img src="@/assets/images/citadex-advantage0.png" alt="" class="statistics_image" />
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>海外先进的管理理念—全周期和动态管理</span>
          </div>
        </div>
        <div class="management_statistics">
          <div class="management_statistics_item">
            <img src="@/assets/images/citadex-advantage1.png" alt="" />
          </div>
          <div class="management_statistics_item">
            <img src="@/assets/images/citadex-advantage2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage citadex_advantage0">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>海外先进的管理理念—私行EAM管理</span>
          </div>
        </div>
        <div class="citadex_advantage3">
          <div class="citadex_advantage3_left">
            <div>
              外部资产管理人EAM (External Asset
              Manager)，于1990年于欧洲起源，由银行家离开私人银行后以个人或团队身份为超高净值客户提供更高质的服务。
            </div>
            <div>
              EAM的优势在于，不持有客户资产，但在以客户为中心，与多家不同的金融机构交流沟通，提供个性化服务。既节约精力成本，又保有独立的专业建议，集高保密性和私隐性于一身，和客户维持长期稳定的客户关系，帮助其家族完成基业长青。
            </div>
            <img src="@/assets/images/citadex-advantage3.png" alt="" />
          </div>
          <div class="citadex_advantage3_right">
            <div>传统私行模式 VS EAM模式</div>
            <img src="@/assets/images/citadex-advantage4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>科学拆解的服务流程</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="citadex_advantage4">
          <div class="citadex_advantage4_left">
            <div>
              根据科学严谨的“CPDS”流程拆解，和人生不同生命周期，经济周期，行业周期，基于客戶生命周期各异的8大目标、7种客戶⻛险承受水平、6大类资产类别等
              个性化偏好设计了多种组合方案，实现了千人千面的客戶投资组合管理。“MDT”
              多学科专家会诊。
            </div>
            <img src="@/assets/images/citadex-advantage5.png" alt="" />
          </div>
          <div class="citadex_advantage4_right">
            <img src="@/assets/images/citadex-advantage6.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>综合的投资管理能力—专业的资产配置经验</span>
          </div>
        </div>
        <div class="citadex_advantage5">
          <div class="citadex_advantage5_left">
            <div class="citadex_advantage5_left_title">
              <div>“资产配置是投资市场唯一的免费午餐”</div>
              <div>——1990年诺贝尔经济学奖得主，哈里·马科维茨</div>
            </div>
            <div class="citadex_advantage5_left_tip">
              以大类资产配置为理念、以市场优质基金为载体、以坚持长期投资为原则、以定制化服务为特色的专户服务，通过科学的资产配置方法、严谨的基金筛选体系，并结合每位投资者的投资目标、风险偏好和个性化投资需求等各类因素，分散投资于一级和二级优质标的，一级主要涵盖硬科技、大健康、人工智能和大消费及ESG投资;二级则围绕权益、固收、货币、另类四大类资产，在严控风
              险的情况下为投资者实现不同周期的财富管理目标。
            </div>
            <div class="citadex_advantage5_left_statistics">
              <div class="title">
                <div>
                  <div>【分散】：</div>
                  <div>分散投资，投资再平衡，攻守相宜</div>
                </div>
                <div>
                  <div>【时间】：</div>
                  <div>坚持长期投资</div>
                </div>
                <div>
                  <div>【复利】：</div>
                  <div>
                    长期累积的复利投资，<br />重视长期持有和波动率的关系
                  </div>
                </div>
              </div>
              <img src="@/assets/images/citadex-advantage7.png" alt="" />
            </div>
          </div>
          <div class="citadex_advantage5_right">
            <img src="@/assets/images/citadex-advantage8.png" alt="" />
            <div class="title">FOF/MOM投资:策略配置优化模型</div>
            <div class="citadex_advantage5_right_tip">
              依据投资人的风险偏好特征，有针对性选择不同投资策略逻辑、
              不同风险控制能力的基金候选池，利用配置模型优化权重。
            </div>
            <div class="title">大类资产类型的种类与分散方式</div>
            <div class="citadex_advantage5_right_ul">
              <div></div>
              <div>类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型:</div>
              <div>货币、债券、股票、大宗商品、黄金、房地产等。</div>
            </div>
            <div class="citadex_advantage5_right_ul">
              <div></div>
              <div>风险分散:</div>
              <div>高风险 vs 低风险</div>
            </div>
            <div class="citadex_advantage5_right_ul">
              <div></div>
              <div>行业分散:</div>
              <div>
                固定收益类 vs 权益类<br />不动产 vs 金融性资产<br />海外资产 vs
                国内资产<br />股票债券 vs 黄金大宗商品
              </div>
            </div>
            <div class="citadex_advantage5_right_ul">
              <div></div>
              <div>平台分散:</div>
              <div>券商 vs 公募 vs 私募 vs 银行等</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>综合的投资管理能力—稀缺底层资产</span>
          </div>
        </div>
        <div class="citadex_advantage6">
          <div class="citadex_advantage6_left">
            <div class="citadex_advantage6_left_title1">
              <div class="left">核心优势</div>
              <div class="right">
                <div class="right1">
                  综合投资管理能力<br />(国际投行并购/流动性资产管理/大额保单整理)
                </div>
                <div class="right2">
                  兼具资产配置能力和动态调整能力<br />1) 底层稀缺资产<br />2)
                  议价能力强，减少交易磨损，节约成本。
                </div>
              </div>
            </div>
            <div class="citadex_advantage6_left_title2">
              <div class="left">
                <div class="left1">
                  <div class="title">
                    <div>01</div>
                    <div>战略资产配置</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>引入顶级投行机构成熟运作经验</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>Goal-Based目标风险</div>
                  </div>
                </div>
                <div class="left1">
                  <div class="title">
                    <div>02</div>
                    <div>战术资产配置</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>基本面量化结合的战术资产配置框架</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>引入货币-信用周期模型</div>
                  </div>
                </div>
                <div class="left1">
                  <div class="title">
                    <div>03</div>
                    <div>动态资产配置</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>事件驱动型资产动态调整</div>
                  </div>
                  <div class="item">
                    <div></div>
                    <div>短周期资产轮动</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="right_list">
                  <div class="right_list_title">方法论</div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>MPT是SMA长周期实践采用的理论框架</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>根据目标风险预算，计算大类资产配置</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>独特优化器兼顾模型经济意义</div>
                    </div>
                  </div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>
                        基本面与量化相结合的宏观、中观、微观三维度战术观点
                      </div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>宏观分析经济周期和货币信用周期的位置</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>中观研究行业景气和估值</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>微观度量市场热度</div>
                    </div>
                  </div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>指定滑动周期的波动率检测</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>短周期资金流向的轮动模型</div>
                    </div>
                  </div>
                </div>
                <div class="right_list">
                  <div class="right_list_title">模型要点</div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>资产长期收益-风险特征估计</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>Goal-Based目标风险准备衡量客户风险偏好</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>协方差矩阵稳健估计</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>定期再平衡的频率选择</div>
                    </div>
                  </div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>货币-信用周期更加关注边际变化</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>行业景气和估值跟踪框架</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>寻找市场资金和机构投资者行为的共振</div>
                    </div>
                  </div>
                  <div class="right_list_item">
                    <div>
                      <div class="point"></div>
                      <div>实时估值工具</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>波动率出发阈值后再平衡机制</div>
                    </div>
                    <div>
                      <div class="point"></div>
                      <div>实证检验特定周期的有效性</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="citadex_advantage6_right">
            <div class="left">
              <div class="title">定量指标</div>
              <div class="item">
                <div class="item_title">权益基金</div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩分析:绝对收益、风险调整后收益、最大回撤、波动率、上下行捕获比
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩归因:择时、行业配置、个股选择能力，业绩一致性
                    持仓风格:市值、行业特征
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">集中度&换手:市值、行业特征</div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    集中度¥换手:前10大持仓稳定性，与管理规模匹配性
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">业绩持续性:风格稳定性</div>
                </div>
              </div>
              <div class="item">
                <div class="item_title">固收基金</div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩分析:绝对收益、风险调整后收益、最大回撤、
                    波动率、上下行捕获比
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩归因:权益/转债，漂息效应、国债效应以及息差效应
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    风险控制:债券违约率、个债集中度、成本计价债券占比
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">业绩持续性:评估体系、债券池入库流程</div>
                </div>
              </div>
              <div class="item">
                <div class="item_title">量化基金</div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩分析:业绩一致性、日超额收益胜率、最大回撤、
                    波动率、上下行捕获比
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    风险控制:风险因子暴露、交易滑点、日内敞口控制、
                    行业及个股集中度、对冲工具选择
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    业绩持续性:策略开发团队、新策略迭代、模型容量
                  </div>
                </div>
              </div>
            </div>
            <div class="left">
              <div class="title">定性指标</div>
              <div class="item">
                <div class="item_title">投研管队</div>
                <div>
                  <div class="point"></div>
                  <div class="text">从业背景:从业经历、投资年限</div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">投研支持:投资与研究团队分工</div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">交易支持:交易员人数，交易流程</div>
                </div>
              </div>
              <div class="item">
                <div class="item_title">投资观点</div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    定期观点:季报及访谈观点如何体现为投资结果
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">实地调研:言行一致性</div>
                </div>
              </div>
              <div class="item">
                <div class="item_title">公司管理</div>
                <div>
                  <div class="point"></div>
                  <div class="text">
                    定期观点:季报及访谈观点如何体现为投资结果
                  </div>
                </div>
                <div>
                  <div class="point"></div>
                  <div class="text">实地调研:言行一致性</div>
                </div>
              </div>
              <div class="item">
                <div class="item_title">风险控制</div>
                <div>
                  <div class="point"></div>
                  <div class="text">风控团队:风控指标、团队配备、灾备系统</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>AI智能的风控体系者-AI金融管家</span>
          </div>
        </div>
        <img src="@/assets/images/citadex-advantage9.png" alt="" class="statistics_image"
          style="width: 90%; margin: 5% 5% 0; box-sizing: border-box" />
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">
            Citadex advantage<br /><span>AI智能的风控体系—全方位高透明的资管系统</span>
          </div>
        </div>
        <div class="citadex_advantage7">
          <div class="header">
            <div>
              直观的可视化交互效果，实时监控，方便快速管理投资资产全貌，提供一张资产负债表展示，在线咨询，同时辅助AI大模型智能问答，区块链记账服务，准确不可篡改，实时管控财富动态。
            </div>
            <div>
              通过智能化风险识别，风险承受能力测试、风险系数及相关系数揭示，智能波动提示、止盈止损预警，时间管理提醒等构建科学智能的风控体系
            </div>
          </div>
          <div class="middle">
            <img src="@/assets/images/citadex-advantage10.png" alt="" />
            <img src="@/assets/images/citadex-advantage11.png" alt="" />
            <img src="@/assets/images/citadex-advantage12.png" alt="" />
            <img src="@/assets/images/citadex-advantage13.png" alt="" />
            <img src="@/assets/images/citadex-advantage14.png" alt="" />
            <img src="@/assets/images/citadex-advantage15.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="service_area">
      <div class="our_advantage_number">Ⅲ</div>
      <div class="our_advantage_tilte">Service area</div>
      <div class="our_advantage_tip">家办的服务</div>
      <div class="title">
        AI 金融管家 | 咨询服务 | 投资管理 | 全面的家办解决方案 | 生活方式定制
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">Service area<br /><span>家办的服务</span></div>
        </div>
        <div class="citadex_advantage8">
          <div class="header">
            AI 金融管家 | <span>咨询服务</span> | 投资管理 | 全面的家办解决方案
            | 生活方式定制
          </div>
          <img src="@/assets/images/citadex-advantage19.png" alt="" class="statistics_image"
            style="width: 90%; margin: 0 5%; box-sizing: border-box" />
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">Service area<br /><span>家办的服务</span></div>
        </div>
        <div class="citadex_advantage8">
          <div class="header">
            AI 金融管家 | 咨询服务 | <span>投资管理</span> | 全面的家办解决方案
            | 生活方式定制
          </div>
          <img src="@/assets/images/citadex-advantage17.png" alt="" class="statistics_image"
            style="width: 90%; margin: 0 5%; box-sizing: border-box" />
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">Service area<br /><span>家办的服务</span></div>
        </div>
        <div class="citadex_advantage8">
          <div class="header">
            AI 金融管家 | 咨询服务 | 投资管理 |
            <span>全面的家办解决方案</span> | 生活方式定制
          </div>
          <img src="@/assets/images/citadex-advantage18.png" alt="" class="statistics_image"
            style="width: 90%; margin: 0 5%; box-sizing: border-box" />
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅴ</div>
          <div class="title">Service area<br /><span>家办的服务</span></div>
        </div>
        <div class="citadex_advantage8">
          <div class="header">
            AI 金融管家 | 咨询服务 | 投资管理 | 全面的家办解决方案 |
            <span>生活方式定制</span>
          </div>
          <img src="@/assets/images/citadex-advantage20.png" alt="" class="statistics_image"
            style="width: 90%; margin: 0 5%; box-sizing: border-box" />
        </div>
      </div>
    </div>
    <div class="our_advantage">
      <div class="our_advantage_number">Ⅳ</div>
      <div class="our_advantage_tilte">VALUE-ADDED SERVICES</div>
      <div class="our_advantage_tip">
        <div></div>
        家办增值服务
        <div></div>
      </div>
      <div class="our_advantage_list" style="width: 100%; justify-content: center; margin-top: 20px">
        <div class="our_advantage_text" style="margin: 0 5%">
          <div class="number">NO.1</div>
          <div class="title">3.0全球顶层架构设计</div>
        </div>
        <div class="our_advantage_text" style="margin: 0 5%">
          <div class="number">NO.2</div>
          <div class="title">下一代子女教育规划</div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅳ</div>
          <div class="title">
            Value-added services<br /><span>全球顶层架构设计—家族信托</span>
          </div>
        </div>
        <div class="management_statistics">
          <div class="management_statistics_item" style="width: 45%">
            <img src="@/assets/images/citadex-advantage21.png" alt="" />
          </div>
          <div class="management_statistics_item" style="width: 53%">
            <img src="@/assets/images/citadex-advantage22.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅳ</div>
          <div class="title">
            Value-added services<br /><span>全球顶层架构设计—海内外双翼驱动</span>
          </div>
        </div>
        <img src="@/assets/images/citadex-advantage23.png" alt="" class="statistics_image"
          style="width: 90%; margin: 5% 5% 0; box-sizing: border-box" />
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅳ</div>
          <div class="title">
            Value-added services<br /><span>下一代子女教育规划</span>
          </div>
        </div>
        <div class="citadex_advantage9">
          <div class="left">
            <div class="title">
              <div class="point"></div>
              <div>智慧父母必修课</div>
              <div>13-24岁青少年升学规划</div>
            </div>
            <div class="list">
              <div>父母升学研修班</div>
              <img src="@/assets/images/to-right.png" alt="" />
              <div>测评系统-1V1升学咨询</div>
              <img src="@/assets/images/to-right.png" alt="" />
              <div>定制化升学规划</div>
              <img src="@/assets/images/to-right.png" alt="" />
              <div>中/高考志愿填报名校定制申请</div>
              <img src="@/assets/images/to-right.png" alt="" />
              <div>学术背景提升名校面试模拟</div>
              <img src="@/assets/images/to-right.png" alt="" />
              <div>实习及工作推荐</div>
            </div>
            <div class="title">
              <div class="point"></div>
              <div>智慧父母必修课</div>
              <div>0-12岁儿童十商培养</div>
            </div>
            <div class="list">
              <div class="number">
                <div>01</div>
                <div>会员主题沙龙课</div>
              </div>
              <div class="number">
                <div>02</div>
                <div>家庭精品定制课</div>
              </div>
              <div class="number">
                <div>03</div>
                <div>家庭专属育儿师</div>
              </div>
              <div class="number">
                <div>04</div>
                <div>1V1育儿咨询</div>
              </div>
              <div class="number">
                <div>05</div>
                <div>儿童十商课程</div>
              </div>
              <div class="number">
                <div>06</div>
                <div>专家资源介绍</div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/images/citadex-advantage24.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage" style="padding: 0 !important">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header" style="padding: 0 5%">
          <div class="number">Ⅳ</div>
          <div class="title">
            Company Profile<br /><span>关于堡得家族办公室</span>
          </div>
        </div>
        <div class="citadex_advantage10">
          <div class="left">
            <div class="title">
              <span>Citadex Family Office堡得家族办公室 (简称“CFO”)，</span>在香
              港及大陆同步注册成立，是一家综合型联合家族办公室，惠泽于香港《施政报
              告》，由境内外顶级投行机构高管、头部机构供应链负责人，全球顶级保险集团高
              管联合发起，创始人团队均毕业于海内外著名学府，拥有丰富的全球资产配置和家
              族财富管理经验，并熟悉科技领域的新兴动态和优质投资标的。
            </div>
            <div class="title">
              CFO或将持有香港1/4/9号牌照、国内私募基金，可以为客戶提供全权委托的资产管理方案和投资建议，重点服务客户为三类，分别为<span>家族信托、大额保险
                金信托、家族慈善基金</span>等需求客户，可投资资产在约2000W-10亿港币 的高净值家庭。
            </div>
            <img src="@/assets/images/citadex-advantage25.png" alt="" />
          </div>
          <div class="right">
            <div class="right_tilte">
              <div class="title">
                <div>
                  CFO立足粤港澳大湾区，立志做亚太地区领先的独立投资管理集团，依托全球顶级
                  私人银行平台，为客戶开展个性化且独立的私人财富管理服务(EAM)，提供一站式
                  财富管理解决平台，帮助资产进行隔离/保全/稳健传承并⻓期稳定增值。
                </div>
                <div>
                  与此同时，
                  CFO为家族办公室成员提供高透明、隐私安全、实时动态跟踪的综合型
                  全方位的智能管理服务，并与国际知名律师楼、会计师事务所、金融机构(如高盛集
                  团、摩根史坦利、瑞士银行、野村集团等)、全球保险机构等有⻓期合作关系。服务
                  内容包括境内外传统及另类财富管理咨询、资本市场咨询以及顶层架构咨询。
                </div>
                <div>
                  CFO始终坚持<span>目标本位管理法，以家族愿景为中心，以价值观为驱动力。</span>看重与客戶共建互信互重、⻓久深厚的合作伙伴关系，通
                  过彼此的坦诚互动，助力客戶在波谲云诡的格局中机敏应变，拆解投资世界错综复
                  杂的形势。
                </div>
              </div>
              <img src="@/assets/images/citadex-advantage26.png" alt="" />
            </div>
            <div class="right_qualification">
              <img src="@/assets/images/citadex-advantage27.png" alt="" />
              <img src="@/assets/images/citadex-advantage28.png" alt="" />
              <img src="@/assets/images/citadex-advantage29.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="citadex_advantage">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅳ</div>
          <div class="title">
            Company Profile<br /><span>堡得家办发展历程及组织架构</span>
          </div>
        </div>
        <img src="@/assets/images/citadex-advantage30.png" alt="" class="statistics_image"
          style="width: 100%; box-sizing: border-box; margin-top: 30px" />
        <img src="@/assets/images/citadex-advantage31.png" alt="" class="statistics_image"
          style="width: 100%; box-sizing: border-box; margin-top: 30px" />
      </div>
    </div>
    <div class="citadex_advantage" style="padding-bottom: 0">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="number">Ⅳ</div>
          <div class="title">
            Financing and<br />Development Planning<br /><span>Partners</span>
          </div>
        </div>
        <img src="@/assets/images/citadex-advantage32.png" alt="" class="statistics_image"
          style="width: 100%; box-sizing: border-box; margin-top: 30px" />
      </div>
    </div>
    <div class="citadex_advantage" style="padding: 0 !important">
      <img src="@/assets/logo.png" alt="" class="image2" />
      <div>
        <div class="market_overview_header">
          <div class="title">
            Summarize<br /><span>重要声明和风险提示</span>
          </div>
        </div>
        <div class="citadex_advantage11">
          <div class="left">
            <div>
              所有内容均为原创，由Citadex FO堡得家族办公室(”Citadex
              FO”)研究和制作。未经Citadex FO
              明确同意，本文件的任何部分都不得以任何形式复制，或在任何其他出版物中提及。本文件不应作为
              研究、投资建议或有关任何产品的建议, 特别是证券投资。
            </div>
            <div>
              本材料仅用于说明、教育或提供信息的目的，并且可能会有变化。本文件并不构成在任何司法管辖区
              出售或招揽购买任何证券的要约。因为在这些司法管辖区出售或招揽是非法的。本文件所包含的信息
              不足以做出投资决定，本文件所包含的任何信息都不应被用作投资决策的依据。与所有投资一样，在
              投资数字资产之前，你应该咨询你的投资、法律和税务专业人士。因为你可能会赔钱。本文件中提到
              的资产的价格和价值以及它们的收入可能会发生波动。过去的表现并不代表任何资产的未来表现。
              Citadex
              FO的赞助者、管理者或建议的投资工具可能会持有本文提及的某些数字资产("产品")，并
              且Citadex
              FO和其各自的员工可能在本文提到的数字资产或产品中持有头寸。本文件中列出的某些信
              息可能包含
              "前瞻性声明"。提供前瞻性声明是为了让潜在投资者有机会了解Citadex
              FO的信念和和观
              点，以便他们可以将这些信念和观点作为评估投资的一个因素。本文件中的信息本文件中的信息不是
              对未来业绩的保证，不应过分依赖这些信息。
            </div>
            <div>
              这种前瞻性声明必然涉及已知和未知的风险和不确定性，这可能导致实际业绩和财务结果与任何这些前瞻性陈述所表达或暗示的未来业绩和财务结果的预测有很大差异。
            </div>
          </div>
          <div class="right">
            <div>
              Citadex
              FO不承担更新以下内容的义务这些陈述仅在本报告发布之日有效。本报告中的陈述，尽管Citadex
              FO已经采取了
              合理的措施来确保本文所包含的信息是准确的，但没有任何陈述或保证(包括对第三方的责任)。你不应该做出任何基于
              这些估计和前瞻性声明的投资决定。你应该仔细考虑每个产品的投资目标、风险因素、费用和开支。投资前应仔细考虑每
              个产品的投资目标、风险因素、费用和支出。这和其他信息可以在每个产品的私人配售备忘录中找到。在投资前仔细阅读
              这些文件。对这些产品的投资是投机性投资，涉及高风险，包括投资资金的部分或全部损失。
            </div>
            <div>
              Citadex
              FO产品不适合任何不能承受全部投资损失的投资者。数字资产的价值是由数字资产在全球交易市场上的供应或需
              求决定的。该市场包括数字资产交易所和其他场所的交易，定价可能是不稳定的，并可能对数字资产的价值产生不利的影
              响。目前，数字资产在零售和商业市场的使用相对较少。投机者对数字资产的使用相对较多，因此造成了价格波动，可能
              对产品的数字资产投资产生不利影响。数字资产交易是不可撤销的，被盗或不正确执行的数字资产交易，可能对产品在数
              字资产中的投资价值产生不利影响。
            </div>
            <div>
              只有那些能够理解与数字资产投资相关的风险的投资者才应该投资于数字资产。本文所述的资产。潜在的投资者应该考虑本文件所包含的假设模拟业绩结果与产品将执行的条件之间的潜在重大差异，可能导致该产品的实际收益与假设的模拟业绩所规定的收益相差甚远。此外，假设性模拟业绩结果所使用的一般市场数据并不体现了实际的交易活动，并且不能被产品在其实际的交易中复制。在选定的历史时期，市场条件可能对产品产生的影响。假设的模拟业绩结果并不反映在所选择的历史时期存在的任何费用。由于这些及其他差异，产品的实际收益可能高于或低于假设的模拟业绩结果，这些结果是假设的，可能永远不会实现。出现偏差的原因还可能包括但不限于监管和/或税法的变化，普遍不利的市场条件等等。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home">
      <img src="@/assets/images/home-background.png" alt="" />
      <div class="title">
        <img src="@/assets/logo.png" alt="" class="image2" style="width: 10%" />
        <div class="title_text2 title_text3" style="letter-spacing: 0px; font-size: 30px">
          围绕一张资产负债表提供全方位cfoGPT金融服务
        </div>
      </div>
      <div class="case_number">
        <div>
          网站备案号：<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023110108号-1</a>
        </div>
        <div style="margin-top: 10px;">深圳堡得家辦科技有限公司版權所有</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      navList: [
        { id: "Ⅰ", title: "家族办公室概况" },
        { id: "Ⅱ", title: "家办行业痛点" },
        { id: "Ⅲ", title: "家办服务范围" },
        { id: "Ⅳ", title: "家办增值服务" },
        { id: "Ⅴ", title: "堡得家办优势" },
        { id: "Ⅵ", title: "关于我们堡得" },
      ],
      timelienList: [
        {
          id: "01",
          title:
            "境内家办行业监管规制尚未落地，准入条件、业务性质、监管规则不明晰;",
        },
        {
          id: "02",
          title:
            "境内家办大多为信托公司、商业银行，保险公司和律师背景，<br />只擅⻓在单一独特领域里做到一枝独秀;",
        },
        {
          id: "03",
          title:
            "境内家办欠缺海外视野和资源整合能力，科学配置和全面财富管理能力;",
        },
        {
          id: "04",
          title:
            "境内家办容易忽视家族利益，买卖关系和KPI考核制度下，<br />无法真正做到以客户利益为中心;",
        },
        {
          id: "05",
          title: "境外家办进入内地获客⻔槛高，通常1000-3000万美金起，展业受限;",
        },
        {
          id: "06",
          title:
            "境外家办内地分支机构少，欠缺高效的沟通渠道，<br />文化和地域差异导致信任成本增加。",
        },
      ],
      policyList: [
        { id: "a", title: "引入新的资本入境计划" },
        { id: "b", title: "提供税务宽减" },
        { id: "c", title: "成立香港财富传承学院" },
        { id: "d", title: "发展香港成为慈善中心" },
        { id: "e", title: "扩大投资推广署Family office hk 专责团队职能" },
        { id: "f", title: "成立新的家族办公室服务提供者网络" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #1e1e1e;

  .home {
    width: 100%;
    position: relative;

    &>img {
      width: 100%;
      background: url("@/assets/images/home-background.png") no-repeat;
      background-size: contain;
      background-position: top center;
    }

    .title {
      width: 100%;
      height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .title_text1 {
        font-size: 60px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      }

      .title_text2 {
        font-size: 44px;
        color: #a07d60;
        margin-top: 20px;
        letter-spacing: 10px;
      }
    }

    .home_btn {
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .home_btn_title {
        font-size: 44px;
        color: #a07d60;
      }

      .home_btn_text {
        width: 60%;
        font-size: 22px;
        color: #6d0624;
        text-transform: uppercase;
        padding: 5px 0;
        background: linear-gradient(to bottom, #3e0415 90%, #1e020b);
      }
    }

    .case_number {
      width: 300px;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: calc(50% - 150px);
      z-index: 2;
      color: #a07d60;

      a {
        display: inline-block;
        padding-top: 50px;
        color: #a07d60;
      }
    }
  }

  .nav {
    width: 100%;
    position: relative;

    &>img {
      width: 100%;
      background: url("@/assets/images/nav.jpg") no-repeat;
      background-size: contain;
      background-position: top center;
    }

    .nav_content {
      position: absolute;
      top: 0;
      left: 8%;
      z-index: 2;
      width: 92%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      img {
        position: absolute;
        top: 40px;
        right: 60px;
        z-index: 3;
        width: 100px;
      }

      .nav_content_title {
        font-size: 44px;
        color: white;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-left: 20px;
        text-align: left;
      }

      .nav_content_list {
        width: 70%;
        margin: 5% 28% 0 2%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;

        .nav_content_list_item {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .item_number {
            min-width: 60px;
            max-width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 50%;
            background-color: #a07d60;
            font-size: 40px;
            font-weight: 400;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
            color: black;
          }

          .item_title {
            font-size: 26px;
            color: #a07d60;
            margin-left: 20px;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
          }
        }
      }
    }
  }

  .market_overview {
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    position: relative;
    background-color: #000000;
    border-top: 1px solid #a07d60;

    &>img {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 3;
      width: 100px;
    }

    .market_overview_header {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .number {
        font-size: 100px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
      }

      .title {
        font-size: 44px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-left: 20px;
        text-align: left;

        span {
          font-size: 26px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          display: block;
        }
      }
    }

    .market_overview_section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .market_overview_section_left {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        .market_overview_section_left_title {
          width: 100%;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          padding: 3px 20px;
          margin-bottom: 20px;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 2px;
          color: #a07d60;
          box-sizing: border-box;
        }

        .market_overview_section_left_text {
          font-size: 16px;
          text-align: justify;
          margin-bottom: 30px;
          line-height: 1.8;
          box-sizing: border-box;

          span {
            color: #a07d60;
            font-size: 26px;
            font-weight: 700;
            font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
              "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
          }
        }
      }

      .market_overview_section_right {
        width: 65%;

        img {
          width: 100%;
        }
      }
    }
  }

  .timeline1 {
    width: 100%;
    box-sizing: border-box;

    .timeline_section {
      margin-top: 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .timeline_section_left {
        width: 40%;

        .timeline_section_left_item {
          height: 60px;
          margin-bottom: 25px;
          border: 1px solid #a07d60;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #2d2d2d;

          .number {
            position: absolute;
            top: -2px;
            left: -31px;
            width: 62px;
            height: 62px;
            line-height: 62px;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            font-family: Impact, Haettenschweiler, "Arial Narrow Bold",
              sans-serif;
            color: #a07d60;
            border-radius: 50%;
            border: 1px solid #a07d60;
            background-color: #3e0415;
          }

          .title {
            color: white;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }

      .timeline_section_right {
        width: 55%;

        img {
          width: 100%;
        }
      }
    }
  }

  .timeline2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2% 5% 8%;
    box-sizing: border-box;
    position: relative;
    background: url("@/assets/images/timeline-back.png") no-repeat;
    background-size: cover;
    border-top: 1px solid #a07d60;

    .image2 {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 3;
      width: 100px;
    }

    .market_overview_header {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .number {
        margin-top: 2%;
        font-size: 100px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
      }

      .title {
        margin-top: 2%;
        font-size: 44px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-left: 20px;
        text-align: left;

        span {
          font-size: 26px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          display: block;
        }
      }
    }

    .timeline2_section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .timeline2_section_title {
        margin: 5% 0;
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        text-align: left;
        padding-left: 20px;
        color: #a07d60;
        font-weight: 700;
        box-sizing: border-box;
        background: linear-gradient(to right, #3e0415, transparent);
      }

      .timeline2_section_title_tip {
        font-size: 26px;
        font-weight: 700;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        color: white;
      }

      .list_title {
        font-size: 16px;
        font-weight: 700;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        color: #a07d60;
        margin: 40px 0 20px;
      }

      .list {
        color: #a07d60;
        display: flex;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;

        .list_left {
          width: 40%;

          .list_title_tip {
            font-size: 16px;
            font-weight: 700;
            color: #a07d60;
            margin-bottom: 20px;
          }

          .options {
            display: flex;
            grid-gap: 20px;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            .list_title_item {
              min-width: 48%;
              text-align: left;
            }
          }
        }

        .list_right {
          max-width: 35%;
          line-height: 2;
        }
      }
    }
  }

  .our_advantage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 5%;
    box-sizing: border-box;
    position: relative;
    background-color: #000000;
    color: #a07d60;
    border-top: 1px solid #a07d60;

    img {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 3;
      width: 100px;
    }

    .our_advantage_number {
      font-size: 100px;
      color: black;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      -webkit-text-stroke: 1px #a07d60;
      padding-top: 2%;
    }

    .our_advantage_tilte {
      font-size: 60px;
      color: #a07d60;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .our_advantage_tip {
      font-size: 30px;
      color: #a07d60;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      text-transform: uppercase;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 60px;
        height: 2px;
        background-color: #a07d60;
        margin: 0 10px;
      }
    }

    .our_advantage_list {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 50px;
      align-items: center;
      padding: 2% 0 5%;

      .our_advantage_text {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: #3e0415;
        border: 1px solid #a07d60;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .number {
          font-size: 40px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        }

        .title {
          width: 60%;
          font-size: 22px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        }
      }
    }
  }

  .citadex_advantage {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2% 5% 5%;
    box-sizing: border-box;
    position: relative;
    background-color: #000000;
    border-top: 1px solid #a07d60;

    .statistics_image {
      width: 100%;
    }

    .image2 {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 3;
      width: 100px;
    }

    .market_overview_header {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .number {
        margin-top: 2%;
        font-size: 100px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
      }

      .title {
        margin-top: 2%;
        font-size: 44px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-left: 20px;
        text-align: left;

        span {
          font-size: 26px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          display: block;
        }
      }
    }

    .management_statistics {
      margin-top: 5%;
      display: flex;
      justify-content: space-between;

      .management_statistics_item {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
          width: 85%;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          padding: 3px 20px;
          margin-bottom: 20px;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 2px;
          color: #a07d60;
          box-sizing: border-box;
        }

        img {
          width: 100%;
        }
      }
    }

    .citadex_advantage3 {
      margin-top: 5%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      color: #a07d60;

      .citadex_advantage3_left {
        width: 30%;
        margin-right: 6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          font-size: 16px;
          text-align: justify;
          margin-bottom: 40px;
        }

        img {
          width: 100%;
        }
      }

      .citadex_advantage3_right {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        div {
          width: 100%;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          padding: 10px 20px;
          margin-bottom: 20px;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 2px;
          color: white;
          box-sizing: border-box;
        }

        img {
          width: 100%;
        }
      }
    }

    .line {
      width: 60%;
      height: 2px;
      margin-top: 2%;
      background-color: #a07d60;
    }

    .citadex_advantage4 {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .citadex_advantage4_left {
        width: 60%;

        div {
          width: 100%;
          color: #a07d60;
          line-height: 2;
          margin-bottom: 2%;
          text-align: left;
        }

        img {
          width: 100%;
        }
      }

      .citadex_advantage4_right {
        width: 30%;

        img {
          width: 100%;
        }
      }
    }

    .citadex_advantage5 {
      margin-top: 3%;
      color: #a07d60;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .citadex_advantage5_left {
        width: 60%;

        .citadex_advantage5_left_title {
          width: 100%;
          padding: 10px 20%;
          box-sizing: border-box;
          border-bottom: 1px solid #a07d60;
          font-size: 18px;
          font-weight: 700;
          font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;

          div {
            width: 100%;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        .citadex_advantage5_left_tip {
          font-size: 16px;
          text-align: justify;
          margin: 20px 0 5%;
          line-height: 2;
        }

        .citadex_advantage5_left_statistics {
          display: flex;
          align-items: flex-end;
          font-size: 16px;

          .title {
            width: 45%;
            margin-bottom: 5%;

            &>div {
              display: flex;
              text-align: left;
            }
          }

          img {
            width: 55%;
          }
        }
      }

      .citadex_advantage5_right {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
          width: 100%;
        }

        .citadex_advantage5_right_tip {
          font-size: 16px;
          color: white;
          text-align: justify;
          margin-bottom: 20px;
        }

        .title {
          width: 100%;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          padding: 2px 20px;
          margin: 20px 0;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 2px;
          color: #a07d60;
          box-sizing: border-box;
        }

        .citadex_advantage5_right_ul {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 20px;
          color: white;

          div {
            &:first-child {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #a07d60;
              margin-top: 10px;
              margin-right: 10px;
            }

            &:nth-child(2) {
              width: 70px;
              font-size: 16px;
              font-weight: 700;
              margin-right: 10px;
            }

            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }

    .citadex_advantage6 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .citadex_advantage6_left {
        width: 49%;

        .citadex_advantage6_left_title1 {
          width: 100%;
          padding: 20px 0;
          font-weight: 700;
          color: white;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .left {
            width: 35%;
            font-size: 18px;
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .right1 {
              text-align: left;
              margin-bottom: 20px;
            }

            .right2 {
              text-align: left;
            }
          }
        }

        .citadex_advantage6_left_title2 {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          .left {
            width: 40%;
            margin-top: 60px;

            .left1 {
              width: 100%;
              box-sizing: border-box;
              padding: 40px 0 40px 10px;
              margin-top: 30px;
              color: white;
              position: relative;
              border: 1px solid #a07d60;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              font-size: 14px;
              font-weight: 700;

              .title {
                width: 140px;
                height: 30px;
                background-color: black;
                position: absolute;
                top: -15px;
                left: calc(50% - 70px);
                z-index: 2;
                display: flex;
                align-items: center;
                color: #a07d60;

                div {
                  &:first-child {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    line-height: 30px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 700;
                    font-family: Cambria, Cochin, Georgia, Times,
                      "Times New Roman", serif;
                    background-color: #3e0415;
                    border: 1px solid #a07d60;
                    margin-right: 5px;
                  }
                }
              }

              .item {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                div {
                  &:first-child {
                    width: 5px;
                    height: 5px;
                    background-color: #a07d60;
                    margin-right: 8px;
                  }
                }
              }
            }
          }

          .right {
            width: 58%;
            display: flex;
            justify-content: space-between;

            .right_list {
              width: 49%;

              .right_list_title {
                width: 100%;
                padding-top: 5px;
                background-color: #3e0415;
                border: 1px solid #a07d60;
                border-radius: 5px;
                color: #a07d60;
              }

              .right_list_item {
                width: 100%;
                height: 140px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                box-sizing: border-box;
                padding: 10px 8px;
                background-color: #d0d0d0;
                font-size: 12px;
                text-align: left;
                color: #3e0415;
                margin-top: 20px;
                border-radius: 5px;

                &>div {
                  display: flex;
                  align-items: flex-start;
                }

                .point {
                  width: 5px;
                  min-width: 5px;
                  height: 5px;
                  background-color: #3e0415;
                  margin-top: 4px;
                  margin-right: 10px;
                }

                &:last-child {
                  height: 90px;
                }
              }
            }
          }
        }
      }

      .citadex_advantage6_right {
        width: 49%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: white;

        .left {
          width: 49%;

          .title {
            width: 100%;
            padding-top: 5px;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            border-radius: 5px;
            color: #a07d60;
          }

          .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            box-sizing: border-box;
            padding: 40px 8px 20px;
            font-size: 12px;
            text-align: left;
            color: white;
            border: 1px solid #a07d60;
            margin-top: 20px;
            position: relative;

            &>div {
              display: flex;
              align-items: flex-start;
            }

            .item_title {
              width: 30%;
              height: 20px;
              background-color: #3e0415;
              border: 1px solid #a07d60;
              position: absolute;
              top: -10px;
              left: 8%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
            }

            .point {
              width: 5px;
              min-width: 5px;
              height: 5px;
              background-color: #a07d60;
              margin-top: 6px;
              margin-right: 10px;
            }

            .text {
              font-size: 12px;
              line-height: 1.6;
            }
          }
        }
      }
    }

    .citadex_advantage7 {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px;

        div {
          &:first-child {
            width: 50%;
            color: #a07d60;
          }

          &:last-child {
            width: 50%;
            border: 2px solid #a07d60;
            background-color: #3e0415;
            border-radius: 5px;
            color: white;
            font-weight: 700;
            padding: 5px;
          }
        }
      }

      .middle {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        img {
          width: 100%;
        }
      }
    }
  }

  .citadex_advantage0 {
    background: url("@/assets/images/timeline-back2.png") no-repeat;
    background-size: cover;
  }

  .service_area {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 5%;
    box-sizing: border-box;
    position: relative;
    // background-color: #000000;
    background: url("@/assets/images/citadex-advantage16.png") no-repeat;
    background-size: cover;
    color: #a07d60;
    border-top: 1px solid #a07d60;

    img {
      position: absolute;
      top: 40px;
      right: 60px;
      z-index: 3;
      width: 100px;
    }

    .our_advantage_number {
      font-size: 100px;
      color: black;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      -webkit-text-stroke: 1px #a07d60;
      padding-top: 2%;
    }

    .our_advantage_tilte {
      font-size: 60px;
      color: #a07d60;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .our_advantage_tip {
      font-size: 36px;
      color: #a07d60;
      font-weight: 700;
      font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
      text-transform: uppercase;
      margin-top: 8%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-size: 20px;
      color: white;
      margin-top: 20px;
      text-align: center;
      font-weight: 700;
      border-top: 2px solid #a07d60;
      border-bottom: 2px solid #a07d60;
      padding: 5px 5%;
      margin-bottom: 8%;
    }
  }

  .citadex_advantage8 {
    .header {
      width: max-content;
      font-size: 20px;
      color: white;
      text-align: center;
      font-weight: 700;
      border-top: 2px solid #a07d60;
      border-bottom: 2px solid #a07d60;
      padding: 5px 5%;
      margin-bottom: 8%;

      span {
        color: #a07d60;
      }
    }
  }

  .citadex_advantage9 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 5%;

    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      color: #a07d60;
      font-size: 16px;
      line-height: 2;

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 60px;

        div {
          &:first-child {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #a07d60;
            margin-right: 10px;
          }

          &:nth-child(2) {
            font-size: 26px;
            font-weight: 700;
            margin-right: 10px;
          }

          &:last-child {
            font-size: 24px;
            color: white;
          }
        }
      }

      .list {
        width: 100%;
        padding: 20px;
        margin-top: 15px;
        background-color: #3e0415;
        border: 1px solid #a07d60;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;

        &>div {
          flex: 1;
        }

        .number {
          text-align: center;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;

          div {
            max-width: 70px;

            &:first-child {
              font-weight: 700;
            }
          }
        }
      }
    }

    .right {
      width: 25%;

      img {
        width: 100%;
      }
    }
  }

  .citadex_advantage10 {
    padding: 2% 5%;
    margin-top: 20px;
    color: white;
    font-size: 14px;
    background: url("@/assets/images/citadex-advantage34.png") no-repeat;
    background-size: cover;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .left {
      width: 35%;

      .title {
        text-align: left;
        line-height: 1.5;
        margin-bottom: 30px;

        span {
          color: #a07d60;
          font-weight: 700;
          font-size: 20px;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        }
      }

      img {
        margin-top: 80px;
        width: 65%;
      }
    }

    .right {
      width: 62%;

      .right_tilte {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          width: 62%;
          text-align: justify;

          div {
            line-height: 1.5;
            margin-bottom: 30px;

            span {
              color: #a07d60;
              font-weight: 700;
              font-size: 20px;
              font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
                serif;
            }
          }
        }

        img {
          min-width: 35%;
          max-width: 35%;
        }
      }

      .right_qualification {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 20%;
        }
      }
    }
  }

  .citadex_advantage11 {
    padding: 2% 5% 5%;
    margin-top: 60px;
    color: #a07d60;
    font-size: 16px;
    display: flex;
    line-height: 1.5;
    text-align: justify;
    align-items: flex-start;
    justify-content: space-between;
    background: url("@/assets/images/citadex-advantage33.png") no-repeat;
    background-size: cover;
    background-position: 100% 100%;

    .left {
      width: 49%;

      div {
        margin-bottom: 30px;
      }
    }

    .right {
      width: 49%;

      div {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .content {
    .home {
      .title {
        .title_text1 {
          font-size: 40px;
        }

        .title_text2 {
          font-size: 24px;
        }
      }

      .home_btn {
        .home_btn_title {
          font-size: 28px;
        }

        .home_btn_text {
          font-size: 18px;
        }
      }
    }

    .nav {
      .nav_content {
        img {
          width: 70px;
        }

        .nav_content_title {
          font-size: 34px;
        }

        .nav_content_list {
          grid-gap: 30px;

          .nav_content_list_item {
            .item_number {
              min-width: 50px;
              max-width: 50px;
              height: 50px;
              line-height: 50px;
              font-size: 30px;
            }

            .item_title {
              font-size: 18px;
            }
          }
        }
      }
    }

    .market_overview {
      &>img {
        width: 70px;
      }

      .market_overview_header {
        .number {
          font-size: 80px;
        }

        .title {
          font-size: 34px;

          span {
            font-size: 20px;
          }
        }
      }

      .market_overview_section {
        .market_overview_section_left {
          .market_overview_section_left_title {
            font-size: 14px;
          }

          .market_overview_section_left_text {
            font-size: 12px;

            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    .timeline1 {
      .timeline_section {
        .timeline_section_left {
          .timeline_section_left_item {
            height: 50px;

            .number {
              left: -30px;
              width: 52px;
              height: 52px;
              line-height: 52px;
              font-size: 24px;
            }

            .title {
              font-size: 10px;
            }
          }
        }
      }
    }

    .timeline2 {
      .image2 {
        width: 70px;
      }

      .market_overview_header {
        .number {
          font-size: 80px;
        }

        .title {
          font-size: 34px;

          span {
            font-size: 20px;
          }
        }
      }

      .timeline2_section {
        .timeline2_section_title {
          height: 50px;
          line-height: 50px;
          font-size: 20px;
        }

        .timeline2_section_title_tip {
          font-size: 22px;
        }

        .list_title {
          font-size: 16px;
        }

        .list {
          .list_left {
            .list_title_tip {
              font-size: 16px;
            }
          }
        }
      }
    }

    .our_advantage {
      img {
        width: 70px;
      }

      .our_advantage_number {
        font-size: 80px;
      }

      .our_advantage_tilte {
        font-size: 50px;
      }

      .our_advantage_tip {
        font-size: 24px;
      }

      .our_advantage_list {
        grid-gap: 40px;

        .our_advantage_text {
          width: 160px;
          height: 160px;

          .number {
            font-size: 32px;
          }

          .title {
            font-size: 16px;
          }
        }
      }
    }

    .citadex_advantage {
      .image2 {
        width: 70px;
      }

      .market_overview_header {
        .number {
          font-size: 80px;
        }

        .title {
          font-size: 34px;

          span {
            font-size: 20px;
          }
        }
      }

      .citadex_advantage3 {
        .citadex_advantage3_left {
          div {
            font-size: 14px;
          }

          img {
            width: 100%;
          }
        }

        .citadex_advantage3_right {
          div {
            font-size: 14px;
          }

          img {
            width: 100%;
          }
        }
      }

      .citadex_advantage4 {
        .citadex_advantage4_left {
          div {
            font-size: 12px;
          }

          img {
            width: 100%;
          }
        }

        .citadex_advantage4_right {
          width: 30%;

          img {
            width: 100%;
          }
        }
      }

      .citadex_advantage5 {
        .citadex_advantage5_left {
          .citadex_advantage5_left_title {
            font-size: 16px;
          }

          .citadex_advantage5_left_tip {
            font-size: 14px;
          }

          .citadex_advantage5_left_statistics {
            font-size: 12px;
          }
        }

        .citadex_advantage5_right {
          .citadex_advantage5_right_tip {
            font-size: 14px;
          }

          .title {
            font-size: 14px;
          }

          .citadex_advantage5_right_ul {
            div {
              &:first-child {
                min-width: 5px;
                max-width: 5px;
              }

              &:nth-child(2) {
                font-size: 14px;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }
      }

      .citadex_advantage6 {
        .citadex_advantage6_left {
          .citadex_advantage6_left_title1 {
            .left {
              width: 35%;
              font-size: 16px;
            }

            .right {
              font-size: 14px;
            }
          }

          .citadex_advantage6_left_title2 {
            .left {
              margin-top: 20px;

              .left1 {
                font-size: 11px;
                text-align: left;

                .title {
                  width: 120px;
                  left: calc(50% - 60px);
                }

                .item {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  div {
                    &:first-child {
                      width: 5px;
                      height: 5px;
                      background-color: #a07d60;
                      margin-right: 8px;
                    }
                  }
                }
              }
            }

            .right {
              .right_list {
                .right_list_title {
                  font-size: 14px;
                }

                .right_list_item {
                  width: 100%;
                  height: 140px;
                  font-size: 11px;

                  &>div {
                    display: flex;
                    align-items: flex-start;
                  }

                  .point {
                    width: 5px;
                    min-width: 5px;
                    height: 5px;
                    background-color: #3e0415;
                    margin-top: 4px;
                    margin-right: 10px;
                  }

                  &:last-child {
                    height: 90px;
                  }
                }
              }
            }
          }
        }

        .citadex_advantage6_right {
          margin-top: 10px;
          width: 49%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          color: white;

          .left {
            width: 49%;

            .title {
              width: 100%;
              padding-top: 5px;
              background-color: #3e0415;
              border: 1px solid #a07d60;
              border-radius: 5px;
              color: #a07d60;
            }

            .item {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              box-sizing: border-box;
              padding: 40px 8px 20px;
              font-size: 12px;
              text-align: left;
              color: white;
              border: 1px solid #a07d60;
              margin-top: 20px;
              position: relative;

              &>div {
                display: flex;
                align-items: flex-start;
              }

              .item_title {
                width: 30%;
                height: 20px;
                background-color: #3e0415;
                border: 1px solid #a07d60;
                position: absolute;
                top: -10px;
                left: 8%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
              }

              .point {
                width: 5px;
                min-width: 5px;
                height: 5px;
                background-color: #a07d60;
                margin-top: 6px;
                margin-right: 10px;
              }

              .text {
                font-size: 12px;
                line-height: 1.6;
              }
            }
          }
        }
      }

      .citadex_advantage7 {
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          margin-bottom: 10px;

          div {
            &:first-child {
              width: 50%;
              color: #a07d60;
              font-size: 14px;
            }

            &:last-child {
              width: 50%;
              border: 2px solid #a07d60;
              background-color: #3e0415;
              border-radius: 5px;
              color: white;
              font-size: 14px;
              font-weight: 700;
              padding: 5px;
            }
          }
        }

        .middle {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 5px;

          img {
            width: 100%;
          }
        }
      }
    }

    .citadex_advantage0 {
      background: url("@/assets/images/timeline-back2.png") no-repeat;
      background-size: cover;
    }

    .service_area {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2% 5%;
      box-sizing: border-box;
      position: relative;
      // background-color: #000000;
      background: url("@/assets/images/citadex-advantage16.png") no-repeat;
      background-size: cover;
      color: #a07d60;

      img {
        position: absolute;
        top: 40px;
        right: 60px;
        z-index: 3;
        width: 70px;
      }

      .our_advantage_number {
        font-size: 80px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
        padding-top: 2%;
      }

      .our_advantage_tilte {
        font-size: 50px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 20px;
      }

      .our_advantage_tip {
        font-size: 30px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 18px;
        color: white;
        margin-top: 20px;
        text-align: center;
        font-weight: 700;
        border-top: 2px solid #a07d60;
        border-bottom: 2px solid #a07d60;
        padding: 5px 5%;
        margin-bottom: 8%;
      }
    }

    .citadex_advantage8 {
      .header {
        width: max-content;
        font-size: 16px;
        color: white;
        text-align: center;
        font-weight: 700;
        border-top: 2px solid #a07d60;
        border-bottom: 2px solid #a07d60;
        padding: 5px 5%;
        margin-bottom: 8%;

        span {
          color: #a07d60;
        }
      }
    }

    .citadex_advantage9 {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-right: 5%;

      .left {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        color: #a07d60;
        font-size: 14px;
        line-height: 2;

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 60px;

          div {
            &:first-child {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #a07d60;
              margin-right: 10px;
            }

            &:nth-child(2) {
              font-size: 20px;
              font-weight: 700;
              margin-right: 10px;
            }

            &:last-child {
              font-size: 18px;
              color: white;
            }
          }
        }

        .list {
          width: 100%;
          padding: 20px;
          margin-top: 15px;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          text-align: center;

          &>div {
            flex: 1;
          }

          .number {
            text-align: center;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;

            div {
              max-width: 70px;

              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }

      .right {
        width: 25%;

        img {
          width: 100%;
        }
      }
    }

    .citadex_advantage10 {
      margin-top: 20px;
      color: white;
      font-size: 12px;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .left {
        width: 35%;

        .title {
          text-align: left;
          line-height: 1.5;
          margin-bottom: 30px;

          span {
            color: #a07d60;
            font-weight: 700;
            font-size: 16px;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
          }
        }

        img {
          margin-top: 80px;
          width: 65%;
        }
      }

      .right {
        width: 62%;

        .right_tilte {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .title {
            width: 62%;
            text-align: justify;

            div {
              line-height: 1.5;
              margin-bottom: 30px;

              span {
                color: #a07d60;
                font-weight: 700;
                font-size: 16px;
                font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
                  serif;
              }
            }
          }

          img {
            min-width: 35%;
            max-width: 35%;
          }
        }

        .right_qualification {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 20%;
          }
        }
      }
    }

    .citadex_advantage11 {
      margin-top: 60px;
      color: #a07d60;
      font-size: 14px;
      display: flex;
      line-height: 1.5;
      text-align: justify;
      align-items: flex-start;
      justify-content: space-between;

      .left {
        width: 49%;

        div {
          margin-bottom: 30px;
        }
      }

      .right {
        width: 49%;

        div {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: #1e1e1e;

    .home {
      width: 100%;
      position: relative;

      &>img {
        width: 100%;
        background: url("@/assets/images/home-background.png") no-repeat;
        background-size: contain;
        background-position: top center;
      }

      .title {
        width: 100%;
        height: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .title_text1 {
          font-size: 22px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        }

        .title_text2 {
          font-size: 14px;
          color: #a07d60;
          margin-top: 15px;
          letter-spacing: 10px;
        }

        .title_text3 {
          font-size: 16px !important;
          color: #a07d60;
          margin: 15px 20% 0;
          letter-spacing: 10px;
        }
      }

      .home_btn {
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .home_btn_title {
          font-size: 14px;
          color: #a07d60;
        }

        .home_btn_text {
          width: 70%;
          font-size: 10px;
          color: #6d0624;
          text-transform: uppercase;
          padding: 5px 0;
          background: linear-gradient(to bottom, #3e0415 90%, #1e020b);
        }
      }

      .image2 {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        width: 30px !important;
      }
    }

    .nav {
      width: 100%;
      position: relative;

      &>img {
        width: 100%;
        background: url("@/assets/images/nav.jpg") no-repeat;
        background-size: contain;
        background-position: top center;
      }

      .nav_content {
        position: absolute;
        top: 0;
        left: 8%;
        z-index: 2;
        width: 92%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        img {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 3;
          width: 30px;
        }

        .nav_content_title {
          font-size: 20px;
          color: white;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          margin-left: 10px;
          text-align: left;
        }

        .nav_content_list {
          width: 80%;
          margin: 5% 28% 0 2%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;

          .nav_content_list_item {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .item_number {
              min-width: 30px;
              max-width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
              background-color: #a07d60;
              font-size: 20px;
              font-weight: 400;
              font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
                serif;
              color: black;
            }

            .item_title {
              font-size: 12px;
              color: #a07d60;
              margin-left: 10px;
              font-weight: 700;
              font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
                serif;
            }
          }
        }
      }
    }

    .market_overview {
      display: flex;
      flex-direction: column;
      padding: 2% 5%;
      position: relative;
      background-color: #000000;
      border-top: 1px solid #a07d60;

      &>img {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        width: 30px;
      }

      .market_overview_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .number {
          font-size: 60px;
          color: black;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          -webkit-text-stroke: 1px #a07d60;
        }

        .title {
          font-size: 24px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          margin-left: 20px;
          text-align: left;

          span {
            font-size: 16px;
            color: #a07d60;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
            text-transform: uppercase;
            display: block;
          }
        }
      }

      .market_overview_section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .market_overview_section_left {
          width: 100% !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: white;

          .market_overview_section_left_title {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 3px 20px;
            margin-bottom: 20px;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            border-radius: 2px;
            color: #a07d60;
            box-sizing: border-box;
          }

          .market_overview_section_left_text {
            font-size: 14px;
            text-align: justify;
            margin-bottom: 30px;
            line-height: 1.8;
            box-sizing: border-box;

            span {
              color: #a07d60;
              font-size: 20px;
              font-weight: 700;
              font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
                "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
            }
          }
        }

        .market_overview_section_right {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }

    .timeline1 {
      width: 100%;
      box-sizing: border-box;

      .timeline_section {
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .timeline_section_left {
          margin: auto;
          width: 90%;

          .timeline_section_left_item {
            height: 60px;
            margin-bottom: 25px;
            padding-left: 40px;
            border: 1px solid #a07d60;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2d2d2d;

            .number {
              position: absolute;
              top: -2px;
              left: -31px;
              width: 62px;
              height: 62px;
              line-height: 62px;
              text-align: center;
              font-size: 28px;
              font-weight: 700;
              font-family: Impact, Haettenschweiler, "Arial Narrow Bold",
                sans-serif;
              color: #a07d60;
              border-radius: 50%;
              border: 1px solid #a07d60;
              background-color: #3e0415;
            }

            .title {
              color: white;
              font-size: 12px;
              font-weight: 700;
            }
          }
        }

        .timeline_section_right {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }

    .timeline2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 2% 5% 8%;
      box-sizing: border-box;
      position: relative;
      background: url("@/assets/images/timeline-back.png") no-repeat;
      background-size: cover;
      border-top: 1px solid #a07d60;

      .image2 {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        width: 30px;
      }

      .market_overview_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .number {
          margin-top: 2%;
          font-size: 60px;
          color: black;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          -webkit-text-stroke: 1px #a07d60;
        }

        .title {
          margin-top: 2%;
          font-size: 24px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          margin-left: 20px;
          text-align: left;

          span {
            font-size: 16px;
            color: #a07d60;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
            text-transform: uppercase;
            display: block;
          }
        }
      }

      .timeline2_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .timeline2_section_title {
          margin: 5% 0;
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          text-align: left;
          padding-left: 20px;
          color: #a07d60;
          font-weight: 700;
          box-sizing: border-box;
          background: linear-gradient(to right, #3e0415, transparent);
        }

        .timeline2_section_title_tip {
          font-size: 20px;
          font-weight: 700;
          font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
          color: white;
        }

        .list_title {
          font-size: 16px;
          font-weight: 700;
          font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
          color: #a07d60;
          margin: 40px 0 20px;
        }

        .list {
          color: #a07d60;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          align-items: flex-start;
          justify-content: flex-start;

          .list_left {
            width: 100%;

            .list_title_tip {
              font-size: 16px;
              font-weight: 700;
              color: #a07d60;
              margin-bottom: 20px;
            }

            .options {
              display: flex;
              grid-gap: 20px;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: flex-start;

              .list_title_item {
                min-width: 48%;
                text-align: left;
              }
            }
          }

          .list_right {
            margin-top: 20px;
            max-width: 100%;
            line-height: 2;
          }
        }
      }
    }

    .our_advantage {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2% 5%;
      box-sizing: border-box;
      position: relative;
      background-color: #000000;
      color: #a07d60;
      border-top: 1px solid #a07d60;

      img {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        width: 30px;
      }

      .our_advantage_number {
        font-size: 60px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
        padding-top: 2%;
      }

      .our_advantage_tilte {
        font-size: 30px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 20px;
      }

      .our_advantage_tip {
        font-size: 18px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 40px;
          height: 2px;
          background-color: #a07d60;
          margin: 0 10px;
        }
      }

      .our_advantage_list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 30px;
        align-items: center;
        padding: 30px 0 5%;

        .our_advantage_text {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .number {
            font-size: 30px;
            color: #a07d60;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
          }

          .title {
            width: 60%;
            font-size: 16px;
            color: #a07d60;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
          }
        }
      }
    }

    .citadex_advantage {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 2% 5% 5%;
      box-sizing: border-box;
      position: relative;
      background-color: #000000;
      border-top: 1px solid #a07d60;

      .statistics_image {
        width: 100%;
      }

      .image2 {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        width: 30px;
      }

      .market_overview_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .number {
          margin-top: 2%;
          font-size: 60px;
          color: black;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          -webkit-text-stroke: 1px #a07d60;
        }

        .title {
          margin-top: 2%;
          font-size: 24px;
          color: #a07d60;
          font-weight: 700;
          font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
          text-transform: uppercase;
          margin-left: 10px;
          text-align: left;

          span {
            font-size: 16px;
            color: #a07d60;
            font-weight: 700;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
            text-transform: uppercase;
            display: block;
          }
        }
      }

      .management_statistics {
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .management_statistics_item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .title {
            width: 85%;
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            padding: 3px 20px;
            margin-bottom: 20px;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            border-radius: 2px;
            color: #a07d60;
            box-sizing: border-box;
          }

          img {
            width: 100%;
          }
        }
      }

      .citadex_advantage3 {
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        color: #a07d60;

        .citadex_advantage3_left {
          width: 100%;
          margin-right: 6%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          div {
            font-size: 16px;
            text-align: justify;
            margin-bottom: 40px;
          }

          img {
            width: 100%;
          }
        }

        .citadex_advantage3_right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          div {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 10px 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            border-radius: 2px;
            color: white;
            box-sizing: border-box;
          }

          img {
            width: 100%;
          }
        }
      }

      .line {
        width: 60%;
        height: 2px;
        margin-top: 2%;
        background-color: #a07d60;
      }

      .citadex_advantage4 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;

        .citadex_advantage4_left {
          width: 100%;

          div {
            width: 100%;
            color: #a07d60;
            line-height: 2;
            margin-bottom: 2%;
            text-align: left;
          }

          img {
            width: 100%;
          }
        }

        .citadex_advantage4_right {
          width: 100%;

          img {
            width: 90%;
          }
        }
      }

      .citadex_advantage5 {
        margin-top: 3%;
        color: #a07d60;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        .citadex_advantage5_left {
          width: 100%;

          .citadex_advantage5_left_title {
            width: 100%;
            padding: 10px 0%;
            box-sizing: border-box;
            border-bottom: 1px solid #a07d60;
            font-size: 14px;
            font-weight: 700;
            font-family: Impact, Haettenschweiler, "Arial Narrow Bold",
              sans-serif;

            div {
              width: 100%;

              &:first-child {
                text-align: left;
              }

              &:last-child {
                text-align: right;
              }
            }
          }

          .citadex_advantage5_left_tip {
            font-size: 16px;
            text-align: justify;
            margin: 20px 0 5%;
            line-height: 2;
          }

          .citadex_advantage5_left_statistics {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            font-size: 16px;

            .title {
              width: 100%;
              margin-bottom: 5%;

              &>div {
                display: flex;
                text-align: left;
              }
            }

            img {
              width: 100%;
            }
          }
        }

        .citadex_advantage5_right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          img {
            width: 100%;
          }

          .citadex_advantage5_right_tip {
            font-size: 14px;
            color: white;
            text-align: justify;
            margin-bottom: 20px;
          }

          .title {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 2px 20px;
            margin: 20px 0;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            border-radius: 2px;
            color: #a07d60;
            box-sizing: border-box;
          }

          .citadex_advantage5_right_ul {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 20px;
            color: white;

            div {
              &:first-child {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #a07d60;
                margin-top: 10px;
                margin-right: 10px;
              }

              &:nth-child(2) {
                width: 70px;
                font-size: 16px;
                font-weight: 700;
                margin-right: 10px;
              }

              &:last-child {
                text-align: left;
              }
            }
          }
        }
      }

      .citadex_advantage6 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .citadex_advantage6_left {
          width: 100%;

          .citadex_advantage6_left_title1 {
            width: 100%;
            padding: 20px 0;
            font-weight: 700;
            color: white;
            background-color: #3e0415;
            border: 1px solid #a07d60;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .left {
              width: 35%;
              font-size: 18px;
            }

            .right {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              .right1 {
                text-align: left;
                margin-bottom: 20px;
              }

              .right2 {
                text-align: left;
              }
            }
          }

          .citadex_advantage6_left_title2 {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;

            .left {
              width: 100%;
              margin-top: 60px;

              .left1 {
                width: 100%;
                box-sizing: border-box;
                padding: 40px 0 40px 10px;
                margin-top: 30px;
                color: white;
                position: relative;
                border: 1px solid #a07d60;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                font-size: 14px;
                font-weight: 700;

                .title {
                  width: 140px;
                  height: 30px;
                  background-color: black;
                  position: absolute;
                  top: -15px;
                  left: calc(50% - 70px);
                  z-index: 2;
                  display: flex;
                  align-items: center;
                  color: #a07d60;

                  div {
                    &:first-child {
                      width: 30px;
                      height: 30px;
                      border-radius: 50%;
                      line-height: 30px;
                      text-align: center;
                      font-size: 18px;
                      font-weight: 700;
                      font-family: Cambria, Cochin, Georgia, Times,
                        "Times New Roman", serif;
                      background-color: #3e0415;
                      border: 1px solid #a07d60;
                      margin-right: 5px;
                    }
                  }
                }

                .item {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  div {
                    &:first-child {
                      width: 5px;
                      height: 5px;
                      background-color: #a07d60;
                      margin-right: 8px;
                    }
                  }
                }
              }
            }

            .right {
              margin: 20px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;

              .right_list {
                width: 49%;

                .right_list_title {
                  width: 100%;
                  padding-top: 5px;
                  background-color: #3e0415;
                  border: 1px solid #a07d60;
                  border-radius: 5px;
                  color: #a07d60;
                }

                .right_list_item {
                  width: 100%;
                  height: 140px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  box-sizing: border-box;
                  padding: 10px 8px;
                  background-color: #d0d0d0;
                  font-size: 12px;
                  text-align: left;
                  color: #3e0415;
                  margin-top: 20px;
                  border-radius: 5px;

                  &>div {
                    display: flex;
                    align-items: flex-start;
                  }

                  .point {
                    width: 5px;
                    min-width: 5px;
                    height: 5px;
                    background-color: #3e0415;
                    margin-top: 4px;
                    margin-right: 10px;
                  }

                  &:last-child {
                    height: 90px;
                  }
                }
              }
            }
          }
        }

        .citadex_advantage6_right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          color: white;

          .left {
            margin-bottom: 20px;
            width: 100%;

            .title {
              width: 100%;
              padding-top: 5px;
              background-color: #3e0415;
              border: 1px solid #a07d60;
              border-radius: 5px;
              color: #a07d60;
            }

            .item {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              box-sizing: border-box;
              padding: 40px 8px 20px;
              font-size: 12px;
              text-align: left;
              color: white;
              border: 1px solid #a07d60;
              margin-top: 20px;
              position: relative;

              &>div {
                display: flex;
                align-items: flex-start;
              }

              .item_title {
                width: 30%;
                height: 20px;
                background-color: #3e0415;
                border: 1px solid #a07d60;
                position: absolute;
                top: -10px;
                left: 8%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
              }

              .point {
                width: 5px;
                min-width: 5px;
                height: 5px;
                background-color: #a07d60;
                margin-top: 6px;
                margin-right: 10px;
              }

              .text {
                font-size: 12px;
                line-height: 1.6;
              }
            }
          }
        }
      }

      .citadex_advantage7 {
        .header {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          margin-bottom: 10px;

          div {
            &:first-child {
              width: 100%;
              color: #a07d60;
              margin: 20px 0;
            }

            &:last-child {
              width: 100%;
              border: 2px solid #a07d60;
              background-color: #3e0415;
              border-radius: 5px;
              color: white;
              font-weight: 700;
              padding: 5px;
            }
          }
        }

        .middle {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 10px;

          img {
            width: 100%;
          }
        }
      }
    }

    .citadex_advantage0 {
      background: url("@/assets/images/timeline-back2.png") no-repeat;
      background-size: cover;
    }

    .service_area {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2% 5%;
      box-sizing: border-box;
      position: relative;
      background: url("@/assets/images/citadex-advantage16.png") no-repeat;
      background-size: cover;
      color: #a07d60;

      .our_advantage_number {
        font-size: 60px;
        color: black;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        -webkit-text-stroke: 1px #a07d60;
        padding-top: 2%;
      }

      .our_advantage_tilte {
        font-size: 40px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 20px;
      }

      .our_advantage_tip {
        font-size: 30px;
        color: #a07d60;
        font-weight: 700;
        font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
        text-transform: uppercase;
        margin-top: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 16px;
        color: white;
        margin-top: 20px;
        text-align: center;
        font-weight: 700;
        border-top: 2px solid #a07d60;
        border-bottom: 2px solid #a07d60;
        padding: 5px 5%;
        margin-bottom: 8%;
      }
    }

    .citadex_advantage8 {
      .header {
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        color: white;
        text-align: center;
        font-weight: 700;
        border-top: 2px solid #a07d60;
        border-bottom: 2px solid #a07d60;
        padding: 5px 5%;
        margin-bottom: 8%;

        span {
          color: #a07d60;
        }
      }
    }

    .citadex_advantage9 {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      margin-right: 5%;

      .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        color: #a07d60;
        font-size: 16px;
        line-height: 2;

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 20px;

          div {
            &:first-child {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #a07d60;
              margin-right: 10px;
            }

            &:nth-child(2) {
              font-size: 16px;
              font-weight: 700;
              margin-right: 10px;
            }

            &:last-child {
              font-size: 14px;
              color: white;
            }
          }
        }

        .list {
          width: 100%;
          box-sizing: border-box;
          padding: 20px;
          margin-top: 15px;
          background-color: #3e0415;
          border: 1px solid #a07d60;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          text-align: center;
          font-size: 8px;

          &>div {
            flex: 1;
          }

          .number {
            text-align: center;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;

            div {
              max-width: 70px;

              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }

      .right {
        margin-top: 20px;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .citadex_advantage10 {
      margin-top: 20px;
      color: white;
      font-size: 14px;

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .left {
        width: 100%;

        .title {
          text-align: left;
          line-height: 1.5;
          margin-bottom: 30px;

          span {
            color: #a07d60;
            font-weight: 700;
            font-size: 20px;
            font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
              serif;
          }
        }

        img {
          margin: 40px 0 20px;
          width: 70%;
        }
      }

      .right {
        width: 100%;

        .right_tilte {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;

          .title {
            width: 100%;
            text-align: justify;

            div {
              line-height: 1.5;
              margin-bottom: 30px;

              span {
                color: #a07d60;
                font-weight: 700;
                font-size: 20px;
                font-family: Cambria, Cochin, Georgia, Times, "Times New Roman",
                  serif;
              }
            }
          }

          img {
            min-width: 100%;
            max-width: 100%;
          }
        }

        .right_qualification {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 33%;
          }
        }
      }
    }

    .citadex_advantage11 {
      margin-top: 30px;
      color: #a07d60;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      line-height: 1.5;
      text-align: justify;
      align-items: flex-start;
      justify-content: space-between;

      .left {
        width: 100%;

        div {
          margin-bottom: 30px;
        }
      }

      .right {
        width: 100%;

        div {
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
